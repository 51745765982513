import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import React, { useEffect, useState } from "react";

interface BaseModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  title: string;
  description?: string;
  onAction1Callback?: () => void;
  action1Title?: string;
  onAction2Callback?: () => void;
  action2Title?: string;
  allowCloseButton?: boolean;
  currentMessageIndex?: number;
  numberOfMessages?: number;
  onNavLeft?: (e: any) => void;
  onNavRight?: (e: any) => void;
  onOpenChange?: (open: boolean) => void;
  isLoading?: boolean;
  shakeModal?: boolean;
  liftModalToCloud?: boolean;
  className?: string;
  overlayClassName?: string;
  children?: React.ReactNode;
  modal?: boolean;
}

const BaseModal: React.FC<BaseModalProps> = ({
  isOpen,
  onClose,
  isLoading,
  title,
  description,
  onAction1Callback,
  action1Title,
  onAction2Callback,
  action2Title,
  allowCloseButton = false,
  currentMessageIndex,
  numberOfMessages,
  onNavLeft,
  onNavRight,
  onOpenChange,
  shakeModal = false,
  liftModalToCloud = false,
  modal = true,
  className = "",
  overlayClassName = "",
  children,
}) => {
  const [actionLoading, setActionLoading] = useState(false);
  const [closeModalAllowed, setCloseModalAllowed] = useState(false);

  const allowPointerDownOutside = () => {
    if (allowCloseButton) {
      if (closeModalAllowed) {
        onClose && onClose();
      }
    } else {
      isOpen = true;
    }
  };

  useEffect(() => {
    // start a timer so that the modal cannot be closed too quickly
    if (isOpen) {
      setTimeout(() => {
        setCloseModalAllowed(true);
      }, 1000);
    } else {
      setCloseModalAllowed(false);
    }
  }, [isOpen]);

  const handleOnOpenChange = (open: boolean) => {
    onOpenChange && onOpenChange(open);
    if (closeModalAllowed && open === false) {
      onClose && onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOnOpenChange} modal={modal}>
      <DialogContent
        allowCloseButton={allowCloseButton}
        onPointerDownOutside={allowPointerDownOutside}
        className={cn(
          "m-2 mr-10 flex max-h-[80vh] w-full max-w-[98%] justify-self-center justify-center rounded-md bg-background-light px-2 py-6 shadow-lg transition-all delay-0 duration-200 ease-in-out md:w-3/5 md:px-8 xl:w-2/5 xl:max-w-[900px]",
          className,
          {
            "animate-floatToCloud": liftModalToCloud,
          }
        )}
        overlayClassName={cn("", overlayClassName)}
        currentMessageIndex={currentMessageIndex}
        numberOfMessages={numberOfMessages}
        onNavLeft={onNavLeft}
        onNavRight={onNavRight}
      >
        <div
          className={cn("h-full w-full", {
            "animate-shake": shakeModal,
            "animate-floatToCloud": liftModalToCloud,
          })}
        >
          <DialogHeader className="pb-4">
            <DialogTitle className={cn("text-darkBlue", {})}>{title}</DialogTitle>
            <DialogDescription>{description ?? undefined}</DialogDescription>
          </DialogHeader>
          <div className="">{children}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

BaseModal.displayName = "BaseModal";

export { BaseModal };
